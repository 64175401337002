import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout/Layout";
import styles from "./why-tappshare.module.scss";
import Title from "../components/UI/Title/Title";
import Button from "../components/UI/Button/Button";
import ArrowImg from "../assets/images/arrow_m_right.svg";
import BgLines from "../assets/images/bg_lines_2.svg";
import Engagements from "../assets/images/engagements.svg";
import ActionsImg from "../assets/images/actions.svg";
import TryNowButton from "../components/TryNowButton/TryNowButton";
import ContactSalesButton from "../components/ContactSalesButton/ContactSalesButton";
import Section from "../components/Section/Section";
import { Swiper, SwiperSlide } from "swiper/react";
import clsx from "clsx";
import WideBackground from "../components/UI/WideBackground/WideBackground";
import SEO from "../components/SEO";
import { useSiteImages } from "../hooks/useAllImages";

const imagesForImport = [
  "members",
  "member_1",
  "member_2",
  "dashboard",
  "infographics",
  "graph",
  "graph_mob",
];

const WhyTappSharePage = () => {
  const images = useSiteImages(imagesForImport);

  return (
    <Layout>
      <SEO title="Why TappShare" />
      <div className={styles.page}>
        <div className={styles.head}>
          <div className={styles.titleContainer}>
            <Title
              title="Optimize word-of-mouth marketing"
              color="white"
              variant="h2"
              weight="extra-bold"
              classProp={styles.title}
            />
            <Title
              title="Increase engagement and conversion by making it easy for team members to share content with their networks"
              color="white"
              weight="lighter"
              variant="h4"
              classProp={styles.subTitle}
            />
          </div>

          <div className={styles.images}>
            <div className={styles.slider}>
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                  bulletClass: `${styles.swiperBullet} swiper-pagination-bullet`,
                  bulletActiveClass: `${styles.swiperBulletActive} swiper-pagination-bullet-active`,
                  modifierClass: `${styles.pagination} swiper-pagination`,
                }}
              >
                <SwiperSlide>
                  <div className={styles.imgSlide}>
                    {/*<Member1Img className={styles.swiperImg1} />*/}
                    <div className={styles.swiperImg1}>
                      <Img fluid={images.member_1} />
                    </div>
                    <div className={styles.arrow}>
                      <p>Swipe</p>
                      <ArrowImg />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.imgSlide}>
                    {/*<Member2Img className={styles.swiperImg2} />*/}
                    <div className={styles.swiperImg2}>
                      <Img fluid={images.member_2} />
                    </div>
                    <div className={styles.arrow}>
                      <p>Swipe</p>
                      <ArrowImg className={styles.arrowLeft} />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className={styles.desktopImg}>
              <Img fluid={images.members} />
            </div>
          </div>

          <div className={styles.buttons}>
            <TryNowButton shadow="primary-dark" />
            <div className={styles.showOnMobile}>
              <ContactSalesButton size="large" iconColor="white" />
            </div>
            <div className={styles.showOnDesktop}>
              <ContactSalesButton variant="outlined" iconColor="white" />
            </div>
          </div>
          <div className={styles.bgShape} />
        </div>

        <Section classProps={styles.goWhereSection}>
          <div className={styles.titleContainer}>
            <Title title="Go where your customers are" variant="h2" />
          </div>

          <Img fluid={images.dashboard} className={styles.laptopImage} />
          <Title
            title="Customers are 35x more likely to open a mobile message and direct messaging drives 40x engagement over other mediums. TappShare makes it easy to create and share content on direct messaging channels."
            variant="text"
            classProp={styles.info}
          />
          <Link to="/">
            <Button
              title="Lear more"
              variant="outlined"
              textColor="primary"
              size="small"
              classProp={styles.learnMore}
            />
          </Link>
          <BgLines className={styles.bgLines} />
        </Section>
        <Section classProps={styles.leverageSection}>
          <div className={styles.titleContainer}>
            <Title
              title="Leverage your teams to engage their networks and build brand trust."
              variant="h3"
            />
          </div>

          <div className={styles.statistics}>
            <div className={styles.infographics}>
              <div className={styles.imgContainer}>
                <div className={styles.trust}>
                  <div className={styles.box}>
                    <p>92%</p>
                    <p>of people trust a message </p>
                  </div>
                  <div className={styles.line} />
                </div>
                <div className={styles.other}>
                  <div className={styles.box}>
                    <div className={styles.line} />
                    <p>8%</p>
                    <p>other</p>
                  </div>
                </div>
                <Img fluid={images.infographics} className={styles.image} />
              </div>
            </div>
            <Title
              title="92% of people trust a message when it comes from someone they know. TappShare equips your team with the right content to share, at the right time, with the right people."
              variant="text"
              classProp={styles.info}
            />
          </div>

          <WideBackground color="aliceblue" />
        </Section>

        <Section classProps={styles.graphSection}>
          <div className={styles.titleContainer}>
            <Title
              title="There isn't a more cost-effective solution"
              variant="h2"
            />
            <p className={styles.info}>
              TappShare has been proven to drive up to
            </p>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.engagement}>
              <div className={styles.infowrap}>
                <Engagements className={styles.icon} />
                <p className={styles.textTop}>91%</p>
                <p className={styles.textBottom}>engagement rates</p>
              </div>
            </div>
            <div className={styles.conversion}>
              <div className={styles.infowrap}>
                <ActionsImg className={styles.icon} />
                <p className={styles.textTop}>67%</p>
                <p className={styles.textBottom}>conversions</p>
              </div>
            </div>
          </div>
        </Section>

        <Section classProps={styles.contactSection}>
          <div className={styles.titleContainer}>
            <Title title="Harness" variant="h3" weight="extra-bold" />
            <Title title="the power of network effects" variant="h3" />
          </div>
          <div className={styles.buttons}>
            <TryNowButton shadow="primary-dark" />
            <div className={styles.showOnMobile}>
              <ContactSalesButton size="large" textColor="primary" />
            </div>
            <div className={styles.showOnDesktop}>
              <ContactSalesButton
                iconColor="primary"
                textColor="primary"
                shadow="primary"
              />
            </div>
          </div>
          <WideBackground color="aliceblue" />
        </Section>
      </div>
    </Layout>
  );
};

export default WhyTappSharePage;
